/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useEffect } from 'react';
import Glide from '@glidejs/glide';
import { isEmpty } from 'lodash';

type Props = {
  id: string;
  label?: string;
  images: string[];
};

const IconSlider = ({ id, label, images }: Props) => {
  const UNIQUE_CLASS = `IconSlider_glide_${id}`;

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 7,
          breakpoints: {
            1280: {
              perView: 7,
            },
            1024: {
              perView: 5,
            },
            768: {
              perView: 4,
            },
            640: {
              perView: 3,
            },
          },
        }).mount();
      }, 10);
    }
  }, [UNIQUE_CLASS]);

  return (
    <div className="container">
      {!isEmpty(label) && (
        <p className="text-center text-sm md:text-base font-bold text-black uppercase my-8">
          {label}
        </p>
      )}

      {images.length > 0 && (
        <div className={`${UNIQUE_CLASS}`}>
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {images.map((imgSrc, i) => {
                return (
                  <li key={i}>
                    <img src={imgSrc} alt={`${label} ${i}`} />
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className="hidden mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {images.map((item, index) => (
              <button
                key={index}
                aria-label="Slide Image"
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconSlider;
